import packageInfo from '../../package.json';

export const environment = {
    production: false,
    host: 'https://api.autocore.pro/',
    hostFile: 'https://api.autocore.pro/api/v1/management/media/',
    whiteListDomains: ['api.autocore.pro'],
    blackListRoutes: [
        '/account/sign-in',
        '/account/sign-up',
        '/account/forgot-password',
        '/account/confirmation-required',
    ],
    company_name: 'Autocore',
    sentryDSN:
        'https://ebb3e4927109c7bd3d097681f2a82bfa@o4506954943823872.ingest.us.sentry.io/4506954962305024',
    sentryEnv: 'production',
    tagName: 'production',
    merchantId: '802322250065623964',
    merchantSiteId: '300318',
    envNuvei:'prod',
    appAuto: 'https://app.autocore.pro/',
    version: packageInfo.version,
};
