import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateChildFn, CanActivateFn } from '@angular/router';

import { AuthService } from '../auth.service';

// @Injectable({ providedIn: 'root' })
// export class AuthGuard implements CanActivate {
//     constructor(private auth: AuthService) {}

//     canActivate(): Promise<boolean> {
//         return new Promise(async (resolve) => {
//             this.auth.checkAuthentication().then((rta) => {
//                 resolve(rta);
//             });
//         //     let result = await inject(AuthService).checkAuthentication();

//         // resolve(!!result);
//         });
//     }
// }cha
export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    return new Promise(async (resolve) => {
        let result = await inject(AuthService).checkAuthentication();
        resolve(!!result);
    });
};
