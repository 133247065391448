<div class="flex justify-center" *ngIf="data?.typeMessage">
    <mat-icon
        [class]="type[data.typeMessage].class"
        [svgIcon]="type[data.typeMessage].icon"
    ></mat-icon>
</div>

<h1
    mat-dialog-title
    class="text-2xl text-center mb-4 font-bold"
    *ngIf="data?.title"
>
    {{ data.title }}
</h1>

<div
    mat-dialog-content
    class="text-lg font-medium text-justify mb-4"
    [ngClass]="{ 'mt-4': !data?.title }"
>
    <p class="text-center">{{ data.message }}</p>
</div>

<div mat-dialog-actions class="flex justify-evenly">
    <button
        class="rounded"
        mat-stroked-button
        color="warn"
        *ngIf="data.showBtnCancel"
        (click)="dialogRef.close(false)"
    >
        {{ "Cancelar" }}
    </button>

    <button
        [class]="
            data?.typeMessage ? type[data.typeMessage].background : 'rounded'
        "
        color="primary"
        mat-raised-button
        (click)="dialogRef.close(true)"
    >
        {{ data.submitText ? data.submitText : "Ok" }}
    </button>
</div>
