import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';

import { AuthService } from '../auth.service';

export const PermissionModuleGuard: CanActivateFn | CanActivateChildFn = (
    route
): Promise<boolean> => {
    return new Promise(async (resolve) => {
        let code: string = route.data?.code;
        let secondaryCodes: string[] = route.data?.secondaryCodes;

        if (code) {
            let result = await inject(AuthService).checkAccessModule(
                code,
                secondaryCodes
            );
            resolve(result);
        } else {
            console.log('add module code to access');

            resolve(false);
        }
    });
};
