import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, ActivatedRoute, RouteConfigLoadEnd } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BasedPreloadingStrategy implements PreloadingStrategy {
  constructor(private activatedRoute: ActivatedRoute) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const routesToPreload = ['account', 'main'];

    let currentRoute = this.activatedRoute;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    if (
      route.data?.['preload'] === true ||
      routesToPreload.includes(route.path) ||
      this.isAnyParentInList(currentRoute, routesToPreload)
    ) {
      return load();
    } else {
      return of(null);
    }
  }

  private isAnyParentInList(route: ActivatedRoute, list: string[]): boolean {
    let currentRoute = route.parent;

    while (currentRoute) {
      if (list.includes(currentRoute.snapshot.routeConfig?.path || '')) {
        return true;
      }

      currentRoute = currentRoute.parent;
    }

    return false;
  }
}
